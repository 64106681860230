import React, { useState } from 'react'
import { ReplyIcon } from '@heroicons/react/outline';

export default function Step2({ prevStep, nextStep, updateFieldValue, total_debt, ...FormFields }) {

    const [value, setValue] = useState(total_debt);

    function separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }

    const handleNextStep = (event) => {
        updateFieldValue("total_debt", value);
        nextStep(2)
    }


    return (
        <div id="step2">
            <div className="text-center col-span-6 sm:col-span-4 p-4">
                <label htmlFor={`total-debt`} className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                    How much do you owe in back taxes?
                </label>
                <p className='text-gray-500 mb-10'>$5,000 minimum</p>
                <div>
                    <input
                        className='slider border border-gray-400 drop-shadow-2xl'
                        type="range" min="5000" max="200000" value={value} step="2500"
                        onChange={({ target: { value: radius } }) => {
                            setValue(radius);
                        }}
                    />
                    <div className='text-center font-semibold text-2xl text-gray-700'>
                        ${separator(value)}
                    </div>
                </div>


                <div className="flex flex-col md:flex-row mt-10 justify-center md:justify-end">
                    <button
                        //add onclick to save all pattern yarn info and post
                        onClick={() => prevStep(2)}
                        className="
            inline-flex 
            items-center 
            justify-center  
            rounded-sm
            mr-3
            md:my-2
            mt-5
            text-sm 
            md:w-fit
            order-last
            md:order-first
            font-medium 
            text-blue-500
            hover:text-blue-800
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                    >
                        <ReplyIcon className='h-3 w-3 mr-2' /> Back
                    </button>
                    <button
                        onClick={(event) => handleNextStep(event)}
                        className="
            inline-flex 
            items-center 
            justify-center  
            rounded-sm
            py-2 
            px-4 
            h-10
            shadow-sm 
            text-sm 
            font-medium 
            text-white bg-blue-500 hover:bg-blue-800
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                    >
                        Next
                    </button>
                </div>
            </div>
            <style>
                {`
                .slider {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 25px;
                    border-radius: 30px;  
                    background: white;
                    outline: none;
                   -webkit-transition: .2s;
                    transition: opacity .2s;
                }
                
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%; 
                    border:none;
                    background: #16A34A;
                    cursor: pointer;
                }
                
                .slider::-moz-range-thumb {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background: #16A34A;
                    border:none;
                    cursor: pointer;
                }
            `}
            </style>
        </div>
    );
}