import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LandingPage from './components/LandingPage';
import Layout from './components/Layout';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions'
import CookiePolicy from './components/CookiePolicy'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path='/' element={<LandingPage did={`888-392-5357`} source={"Andrews Wharton Inc"} />} />
          {/* <Route path='/taxinfo' element={<LandingPage did={`123-234-2345`} source={"Craftwise LLC - Google1"} />} />
          <Route path='/taxhelp' element={<LandingPage did={`123-234-2345`} source={"Craftwise LLC - Google2"} />} /> */}
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/terms-conditions' element={<TermsConditions />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;