import { Outlet } from "react-router-dom";
import Navbar from './Navbar'
import { useLocation } from 'react-router-dom'

const Layout = (props) => {

  const location = useLocation();
  return (
    <>
      {
        location.pathname === '/terms-conditions'
        &&
        <Navbar />
      }
      {
        location.pathname === "/cookie-policy"
        &&
        <Navbar />
      }
      {
        location.pathname === "/privacy-policy"
        &&
        <Navbar />
      }
      <Outlet />
      <footer className="text-center text-gray-100 p-2 bg-gray-900">
        <div className="mx-auto max-w-4xl py-10 text-center md:text-left">
          <div className="text-center px-1 text-sm italic leading-tight">
            123TaxDebtFree.com is not a broker, direct lender, or insurance provider, nor is it affiliated with any governmental organizations.
            The informational forms provided on 123TaxDebtFree.com’s site do not constitute as an application for debt settlement, nor is the information provided used to pre-qualify you with any lenders.
            There is no guarantee that you will be approved for rates displayed.
          </div>
          <div className='text-center mt-2 text-white text-sm'>
            <a href="/terms-conditions" className='hover:underline'>Terms &amp; Conditions</a>
            <a href="/privacy-policy" className='ml-2 hover:underline'>Privacy Policy</a>
            <a href="/cookie-policy" className='ml-2 hover:underline'>Cookie Policy</a>
          </div>
        </div>
        <div className="text-center p-2">
        <img src="/ovation-logo-white.png" alt="Ovation Tax Group" className='h-10 mt-4 mb-2 mx-auto' />
          <div className="text-gray-100 font-semibold text-sm">© 2022 <a href="https://www.ovationtaxgroup.com/" className="hover:underline">Ovation Tax Group</a>. All Rights Reserved.</div>
        </div>
      </footer>
    </>
  )
};

export default Layout;