import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Success from './Success';
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';
import { PhoneOutgoingIcon } from '@heroicons/react/outline';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;

export default function StepForm(props) {
  const [currentStep, setCurrentStep] = useState(1)

  // const bottomRef = useRef(null);

  // useEffect(() => {
  //   // 👇️ scroll to bottom every time messages change
  //   bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  // }, [currentStep]);

  const prevStep = (step) => {
    setCurrentStep(step - 1)
  }

  const nextStep = (step) => {
    setCurrentStep(step + 1)
  }

  const today = new Date()
  const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
  const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);

  const online = () => {
    //if weekend
    if (isWeekend) {
      return null
    } else {
      //if its 6am-6pm
      if (isOpen) {
        return (
          <div className='flex items-center justify-center'>

            <div className='relative'>
              <span className="animate-ping absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
              <span className="absolute inline-flex rounded-full h-3 w-3 bg-green-500 mr-1"></span>
            </div>
            <div className='ml-5 mt-2 text-base'>Active</div>
          </div>
        )
      } else {
        return null
      }
    }
  }

  const steps = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            owe_over_5k={props.dataArray.owe_over_5k}
            other={props.dataArray.other}
          />
        )
      case 2:
        return (
          <Step2
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            total_debt={props.dataArray.total_debt}
          />
        )
      case 3:
        return (
          <Step3
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            state={props.dataArray.state}
            zip_code={props.dataArray.zip_code}
            owe_over_5k={props.dataArray.owe_over_5k}
          />
        )
      case 4:
        return (
          <Step4
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            first_name={props.dataArray.first_name}
            last_name={props.dataArray.last_name}
            phone_number={props.dataArray.phone_number}
            email_address={props.dataArray.email_address}
            owe_over_5k={props.dataArray.owe_over_5k}
          />
        )
      case 5:
        return (
          <Step5
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            additional={props.dataArray.additional}
            agree={props.dataArray.agree}
            dataArray={props.dataArray}
            did={props.did}
            source={props.source}
            setSuccess={props.setSuccess}
          />
        )
      case 6:
        return (
          <Success did={props.did} />
        )
      // never forget the default case, otherwise VS code would be mad!
      default:
      // do nothing
    }
  }

  return (
    <div className="md:mt-10 md:pb-10 max-w-3xl mx-auto">
      {/* progress bar */}
      <div className="w-full bg-gray-200 h-10">
        <div className="bg-green-600 h-10"
          style={
            currentStep === 1 ? { width: '20%' }
              : currentStep === 2 ? { width: '40%' }
                : currentStep === 3 ? { width: '60%' }
                  : currentStep === 4 ? { width: '80%' }
                    : { width: '100%' }}></div>
      </div>
      {/* content */}
      <div className="md:p-10 p-4 mx-auto drop-shadow-2xl bg-white/70">
        <h3 className="text-center mb-4 leading-8 font-extrabold tracking-tight md:flex items-baseline justify-center text-gray-900 text-3xl md:text-4xl">
          Tax Forgiveness Programs Available
        </h3>
        <div className='flex flex-col justify-center items-center text-gray-900'>
          <span className='text-sm italic'>in partnership with</span>
          <div className='flex items-center font-bold text-xl wendy uppercase tracking-widest'>
            <img
              className="block mt-2 mx-2 h-8 w-auto not-italic"
              src="/ovation-logo.png"
              alt="Ovation"
            /> Ovation Tax Group
          </div>
        </div>
        <p className="text-center mt-3 text-base text-gray-800 sm:mt-5 mx-auto max-w-md sm:text-lg sm:mx-auto md:mt-5 md:text-xl">The Government has made NOW, the <b>BEST</b> time to finally restructure or even resolve your tax debt.</p>

        <div className='bg-white box-shadow-lg p-2 mt-5 md:my-20 mb-5 max-w-2xl mx-auto'>
          {currentStep === 2 && <SlideInUpDiv className='text-center mt-5 text-sm italic text-green-600 font-semibold'>Looks good! Keep going!</SlideInUpDiv>}
          {currentStep === 3 && <FadeInRightDiv className='text-center mt-5 text-sm italic text-green-600 font-semibold'>Got it! You&apos;re more than halfway done!</FadeInRightDiv>}
          {currentStep === 4 && <SlideInUpDiv className='text-center mt-5 text-sm italic text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
          {currentStep === 5 && <FadeInRightDiv className='text-center mt-5 text-sm italic text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>}
          {steps()}
        </div>
      </div>

    </div>
  )

}