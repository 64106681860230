import React from 'react';

export default function Navbar() {
    return (
        <div>
            <div className='banner'>
                <a href="/">
                    <div className='py-10 flex items-center justify-center font-serif text-2xl text-white'>
                        <img src="/logo_light.png" alt="123tax debt free" className='h-10' />.com
                    </div>
                </a>
            </div>
        </div>
    );
}
