import React, { useState, useEffect } from 'react'
import StepForm from './StepForm';
import BackButtonModal from './BackButtonModal';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { StarIcon } from '@heroicons/react/solid';
import { PhoneOutgoingIcon } from '@heroicons/react/outline';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function LandingPage(props) {

    const [dataArray, setDataArray] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        state: "FL",
        zip_code: "",
        years_unFiled: "",
        total_debt: 10000,
        owe_over_5k: "",
        additional: "",
        other: "",
        agree: true,
    })

    const [back, setBack] = useState(false)
    const [backOnce, setBackOnce] = useState(false)
    const [success, setSuccess] = useState(false)

    // console.log("current data", dataArray)

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
    }

    //for the back button/leaving
    useEffect(() => {
        var mouseY = 0;
        var topValue = 0;
        if (backOnce === true) {
            setBack(false)
            const timer = setTimeout(() => {
                setBackOnce(false)
            }, 60000); //60 seconds
            return () => clearTimeout(timer);
        } else {
            window.addEventListener("mouseout", function (e) {
                mouseY = e.clientY;
                if (mouseY < topValue) {
                    setBack(true)
                }
            },
                false);
        }
    }, [backOnce])


    const today = new Date()
    const isWeekend = (today.getDay() === 6) || (today.getDay() === 0);
    const isOpen = (today.getHours() >= 6) && (today.getHours() <= 18);


    const testimonialArray1 = [
        {
            id: 1,
            name: "Angel Reyes",
            title: "Best possible result...",
            text: "I want to thank you for everything you did to resolve my mother’s estate tax matter with IRS. You produced the best possible result. The beneficiaries are most grateful and happy to recommend you to others.",
            date: "a month ago"
        },
        {
            id: 2,
            name: "Nick Burton",
            title: "Recommend to my family...",
            text: "I would definitely give Ovation Tax Group five stars I went there to file my taxes and got back more than I could even expect I will be going there every year from now on yes I would recommend my family.",
            date: "5 months ago"
        },
        {
            id: 3,
            name: "Antonio Montana",
            title: "Five stars...",
            text: "I've been coming to Ovation Tax Group for a very long time and every time I was satisfied I would recommend to my family and friends they can help you from doing your taxes to help with the IRS five stars all the way.",
            date: "5 months ago"
        },
        {
            id: 4,
            name: "Bonnie Smith",
            title: "Highly professional and friendly service...",
            text: "I can't recommend Ovation Tax Group highly enough. They helped and guided me through every step of the way through a recent tax matter allowing me to have complete peace of mind through the whole process. Engaging a an expert like this lawyers was definitely the best thing I did...",
            date: "5 months ago"
        },
        {
            id: 5,
            name: "Will Dent",
            title: "Clear path forward...",
            text: "After numerous attempts to resolve the matter on our own, we went to the Ovation Tax group and had a great meeting with Ms. Kim. She provided us with a clear path forward and took away all the anxiety from dealing with the issue ourselves.",
            date: "5 months ago"
        },
        {
            id: 6,
            name: "Ashley Bank",
            title: "Knowledgeable, professional, and worked extremely...",
            text: "This team is phenomena! I experienced an unexpected wage garnishment and contacted the team Ovation Tax Group. They are very knowledgeable, professional, and worked extremely fast as well. They resolved my tax issue within 48 hours! ",
            date: "5 months ago"
        },
    ]



    return (
        <div>
            {
                !isWeekend && isOpen && back && !success &&
                <div>
                    <BackButtonModal did={props.did} open={back} setOpen={setBack} backOnce={backOnce} setBackOnce={setBackOnce} />
                </div>
            }
            <div className='banner min-h-fit'>
                <div className='p-5 flex items-center justify-center sm:justify-between'>
                    <div>
                        <a href="/">
                            <div className='flex items-center justify-center font-serif text-2xl text-white'>
                                <img src="/logo_light.png" alt="123tax debt free" className='h-10' />
                            </div>
                        </a>
                    </div>
                    <div className='hidden sm:block'>
                        <p className='block mb-0 font-bold text-white text-sm capitalize text-right'>Free Tax Debt Consultation</p>
                        <a id="navPhoneButton" href={`tel:${props.did}`}>
                            <div className="block mx-auto md:mx-0 w-fit rounded-sm hover:shadow-sm drop-shadow-2xl text-white hover:text-yellow-400 font-bold text-3xl">
                                {props.did}
                            </div>
                        </a>

                    </div>
                </div>
                <div className='sm:hidden block text-center'>
                    <div className='bg-gray-300 p-1'>
                        <p className="text-gray-700 uppercase text-xs font-semibold">Free Quote Today:</p>
                    </div>
                    <button href={`tel:${props.did}`} id="CTAPhoneButtonMobile" className='w-full bg-red-600 p-2'>
                        <span className='text-white font-bold tracking-wide text-xl'>{props.did}</span>
                    </button>
                </div>
                <StepForm
                    dataArray={dataArray}
                    updateFieldValue={updateFieldValue}
                    did={props.did}
                    source={props.source}
                    setSuccess={setSuccess}
                />
                <div className='bg-white'>
                    {/* <FadeIn className='max-w-6xl mx-auto grid md:grid-cols-3 gap-4'> */}

                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                    >
                        {testimonialArray1.map((item) => (
                            <SwiperSlide key={item.id} className="p-2 md:p-5">
                                <div className='max-w-4xl mx-auto items-center justify-center relative'>
                                    <div className='md:grid grid-cols-4 my-auto mx-5 cursor-default p-6'>
                                        <div className='col-span-1'>
                                            <div className='flex items-center justify-start'>
                                                <img src="google_logo.png" className='h-8 w-8' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                            </div>
                                            <p className='font-semibold text-lg mb-0 text-gray-800'>{item.name}</p>
                                            <p className='font-normal text-sm mb-2 md:mb-0 text-gray-600'>{item.date}</p>
                                        </div>
                                        <p className='col-span-3 text-md text-left text-base md:text-lg text-gray-500 leading-5'>"{item.text}"</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* end testimonials */}
            </div>
        </div>
    );
}