import React, { useEffect, useRef } from 'react';
import { XCircleIcon, CheckCircleIcon, XIcon } from '@heroicons/react/outline';
// import InputMask from 'comigo-tech-react-input-mask';
import Input from 'react-phone-number-input/input'

export default function Step3({ prevStep, nextStep, updateFieldValue, owe_over_5k, ...FormFields }) {


    const topRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to top for this step
        topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });

    const errorObj = Object.keys(FormFields).reduce((prev, curr) => (prev[curr] = false, prev), {})

    //create an object of key value pair set to false each with a key === names of all basic form field keys
    const [errors, setErrors] = React.useState({ ...errorObj })

    const [results, setResults] = React.useState(true)

    const [phone, setPhone] = React.useState(FormFields.phone_number);

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    const validFormFields = () => {
        let updateErrorObj = { ...errors };
        Object.entries(FormFields).some(([key, value]) => {
            if (key === "email_address") {
                if (!validEmail.test(value) || !value || value.length === 0) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }
            } else if (key === 'phone_number') {
                if (!phone || phone.replace("+", '').length !== 11) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }

            } else if (!value) {
                updateErrorObj[key] = true;
                // console.log("error", key, value)
            } else {
                updateErrorObj[key] = false
            }
        })
        setErrors(updateErrorObj)
        return (Object.values(updateErrorObj).includes(true)) ? false : true
    }


    const handleNextStep = () => {

        if (validFormFields()) {
            // if no errors...
            try {
                nextStep(4)
                updateFieldValue("phone_number", phone.replace("+", ''))
                //post to a db
            } catch (error) {
                console.error('error', error);
            } finally {
            }
        }
    }



    if (!results) {
        return (
            <div id="step4" className='text-center md:text-left col-span-6 sm:col-span-4 p-4'>
                <h3 className='text-gray-500 mb-5'>Please enter your contact information so one of our partners can contact you directly.</h3>
                {Object.entries(FormFields).map(([key, value]) =>
                (key === 'phone_number' ?
                    <div key={key} className="col-span-6 sm:col-span-4 mb-6">
                        <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-700 capitalize">
                            {key.replace(/_/g, ' ')}
                        </label>
                        <Input
                            defaultCountry="US"
                            value={phone}
                            onChange={setPhone}
                            className="p-4 mt-1 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-sm"
                        />
                        {errors[key] &&
                            <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid phone number.
                            </p>
                        }
                    </div>
                    :
                    <div key={key} className="col-span-6 sm:col-span-4 mb-6">
                        <label htmlFor={`${value}-${key}`} className="block text-sm font-medium text-gray-700 capitalize">
                            {key.replace(/_/g, ' ')}
                        </label>
                        <input
                            type="text"
                            name={key}
                            maxLength="50"
                            id={`${value}-${key}`}
                            defaultValue={`${value}`}
                            onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                            className="p-4 mt-1 h-10 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-sm"
                        />
                        {errors[key] &&
                            <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid {key.replace(/_/g, ' ')}.
                            </p>
                        }
                    </div>
                )
                )}

                <div className="mt-10 text-right">
                    <button
                        onClick={() => handleNextStep()}
                        className="
                inline-flex 
                items-center 
                justify-center  
                rounded-sm
                py-2 
                px-4 
                w-full
                shadow-sm 
                h-10
                text-sm 
                font-medium  
                text-white bg-blue-500 hover:bg-blue-800
                focus:outline-none 
                focus:ring-2 
                focus:ring-offset-2 
                focus:ring-blue-500"
                    >
                        Next
                    </button>
                </div>
            </div>
        )
    } else {
        return (
            <div ref={topRef} className='text-center md:text-left col-span-6 sm:col-span-4 p-4'>
                {owe_over_5k ?
                    <div className="text-center col-span-6 sm:col-span-4 p-4">
                        <label className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                            <b>Congratulations</b>, you qualify for a free consultation! Are you ready to get started?
                        </label>
                        <div className='flex flex-col'>
                            <button
                                onClick={() => setResults(false)}
                                className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-sm text-white bg-blue-500 hover:bg-blue-700">
                                <CheckCircleIcon className="h-4 w-4 mr-1" />Yes, I'm ready ASAP
                            </button>
                            <button onClick={() => setResults(false)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-sm text-white bg-blue-500 hover:bg-blue-700">
                                <XIcon className="h-4 w-4 mr-1" />No, I'm not ready
                            </button>
                        </div>
                    </div>
                    :
                    <div className="text-center col-span-6 sm:col-span-4 p-4">
                        <label className="mb-5 block text-md md:text-xl font-medium text-gray-800">
                            You currently do not qualify for any of our partners' current offers.
                            Would you still like to speak to one of our representatives?
                        </label>
                        <div className='flex flex-col'>
                            <button
                                onClick={() => setResults(false)}
                                className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-sm text-white bg-blue-500 hover:bg-blue-700">
                                <CheckCircleIcon className="h-4 w-4 mr-1" />Yes, put me in contact with a rep
                            </button>
                            <button onClick={() => setResults(false)} className="hover:translate-y-0.5 hover:drop-shadow-sm drop-shadow-lg my-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium rounded-sm text-white bg-blue-500 hover:bg-blue-700">
                                <XCircleIcon className="h-4 w-4 mr-1" />No, I don't want help
                            </button>
                        </div>
                    </div>}


            </div>
        );
    }
}