import React, { useState, useEffect, useRef } from 'react'
import { ReplyIcon, XCircleIcon } from '@heroicons/react/outline';
import JSConfetti from 'js-confetti';

export default function Step5({ prevStep, nextStep, setSuccess, updateFieldValue, additional, agree, dataArray, did, source }) {

    const [agreeErr, setAgreeErr] = useState(false);

    // const location = useLocation();
    const jsConfetti = new JSConfetti()

    const topRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to top for this step
        topRef.current?.scrollIntoView({ behavior: 'smooth', block:'center' });
    });

    const notifyWebhook = async (url, body) => {
        // console.log("POST", url, body)
        return fetch(url, {
            mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    const date = new Date();

    const pst = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    const handleSubmit = () => {
        if (agree) {
            // if no errors...
            try {
                setAgreeErr(false)
                nextStep(5)
                //add timestamp
                dataArray['time_stamp'] = pst + " PST"
                dataArray['url'] = window.location.href
                dataArray['did'] = did
                // dataArray['source'] = source
                //console.log(dataArray)
                //use zapier
                notifyWebhook(source.includes("Craftwise") ? "https://hooks.zapier.com/hooks/catch/13095819/bqknqwg/" : "https://hooks.zapier.com/hooks/catch/13095819/bqkvgsp/", dataArray)
                jsConfetti.addConfetti()
                setSuccess(true)

            } catch (error) {
                console.error('error', error);
            }
        } else {
            setAgreeErr(true)
        }
    }

    // const handleSubmit = async () => {
    //     if (agree) {
    //         // if no errors...
    //         try {
    //             setAgreeErr(false)
    //             console.log("POST + add jornaya")
    //             nextStep(5)
    //             //post to a db
    //             const { data: taxLeadInsert, error: taxLeadInsert_error, status: taxLeadInsert_status } = await supabase
    //                 .from("tax_leads")
    //                 .insert([
    //                     {
    //                         first_name: dataArray.first_name,
    //                         last_name: dataArray.last_name,
    //                         email_address: dataArray.email_address,
    //                         phone_number: dataArray.phone_number,
    //                         state: dataArray.state,
    //                         zip_code: dataArray.zip_code,
    //                         total_debt: dataArray.total_debt,
    //                         owe_over_5k: dataArray.owe_over_5k,
    //                         additional: dataArray.additional,
    //                         other: dataArray.other,
    //                         agree: dataArray.agree,
    //                         did: did,
    //                         url: location.href,
    //                         source: source
    //                     }
    //                 ])

    //             if (taxLeadInsert_error && taxLeadInsert_status !== 406) {
    //                 throw taxLeadInsert_error;
    //             }

    //             //works correctly
    //             if (taxLeadInsert) {
    //                 console.log('Successfully submitted information.')
    //             }
    //         } catch (error) {
    //             console.error('error', error);
    //         } finally {
    //         }
    //     } else {
    //         setAgreeErr(true)
    //     }
    // }


    return (
        <div ref={topRef} id="step5" className='mt-5 text-center md:text-left col-span-6 sm:col-span-4 p-4'>

            <div className="col-span-6 sm:col-span-4 mb-6">
                <label htmlFor={`additional`} className="block text-sm font-medium text-gray-700">
                    Any additional information you would like us to know prior to being contacted?
                </label>
                <p className='mt-1 text-gray-600 text-xs italic'>Optional (100 character limit)</p>
                <div className="mt-1">
                    <textarea
                        id="additional"
                        name="additional"
                        rows={3}
                        maxLength={100}
                        className="p-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-sm"
                        onChange={(event) => updateFieldValue('additional', event.target.value)}
                        value={additional}
                    />
                </div>
            </div>

            <div className="text-left col-span-6 sm:col-span-4 mb-6">

                <input className="mr-2"
                    type="checkbox"
                    id={`flexCheckDefault`}
                    checked={agree}
                    onChange={(event) => updateFieldValue("agree", event.target.checked)}
                />

                <label className="text-xs italic text-gray-700" htmlFor="flexCheckDefault">
                    I agree to share my information with the following partner representatives,
                    and for them to contact me at the phone number and email provided above (including through automated means;
                    e.g. auto-dialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or
                    email, even if your telephone number is currently listed on any state, federal or corporate "Do Not Call" list.
                    This is not a condition of purchase.  Standard message and data rates may apply. For more information, visit
                    the <a href="/privacy-policy" className='underline'>Privacy Policy</a> of 123TaxDebtFree.com.
                </label>
                {agreeErr &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                        <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
                    </p>
                }
            </div>

            <div className="flex flex-col mt-10 justify-center">
                <button
                    onClick={() => handleSubmit()}
                    className="
            inline-flex 
            items-center 
            justify-center  
            rounded-sm
            py-2 
            px-4 
            shadow-sm 
            text-xl
            h-14
            font-bold 
            text-white bg-green-600 hover:bg-green-800
            focus:outline-none 
            uppercase
            tracking-widest
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                >
                    Submit
                </button>
                <button
                    //add onclick to save all pattern yarn info and post
                    onClick={() => prevStep(5)}
                    className="
                    inline-flex 
                    items-center 
                    justify-center  
                    mr-3
                    mt-5
                    text-sm 
                    w-full
                    order-last
                    font-medium 
                    text-gray-900
                    hover:text-blue-500
            focus:outline-none 
            focus:ring-2 
            focus:ring-offset-2 
            focus:ring-blue-500"
                >
                    <ReplyIcon className='h-3 w-3 mr-2' /> Back
                </button>
            </div>
        </div>
    );
}